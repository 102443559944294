import React from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import ImgComponent from "../content/imgComponent"
import "../../../css/sliderUpdate.css"

const EventSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  }

  const sliderImages = [
    {
      id: 1,
      image: "event1.jpg",
    },
    {
      id: 2,
      image: "event2.jpg",
    },
    {
      id: 3,
      image: "event3.jpg",
    },
    {
      id: 4,
      image: "event4.jpg",
    },
    {
      id: 5,
      image: "event5.jpg",
    },
  ]

  return (
    <>
      <Slider {...settings} className="slider-content btn-style-1 events-slider">
        {sliderImages.map((sliderImage) => (
          
            <ImgComponent pmlImg={sliderImage.image} className="attachment-full size-full imgEmir" />
          
        ))}
      </Slider>
    </>
  )
}

export default EventSlider
