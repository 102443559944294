import React from "react"
import { Col, Row } from "reactstrap"

const VisionMission = () => {
  return (
    <section className="container">
      <Row className="d-flex text-center mt-5">
        <Col md={4} sm={12}>
          <div>
            <i class="far fa-eye fa-3x" />
            <h2 className="vismis-title">Our Vision</h2>
            <p className="vismis-desc">
              To become World Class Contract Research Organization recognized by
              local and international customer.
            </p>
          </div>
        </Col>
        <Col md={4} sm={12}>
          <div>
            <i class="fas fa-bullseye fa-3x" />
            <h2 className="vismis-title">Our Mission</h2>
            <p className="vismis-desc">
              To ensure good quality of health care product for the community.
            </p>
          </div>
        </Col>
        <Col md={4} sm={12}>
          <div>
            <i class="fas fa-hands fa-3x" />
            <h2 className="vismis-title">Our Value</h2>
            <p className="vismis-desc">
              Deliver best services through strong experiences and innovation.
            </p>
          </div>
        </Col>
      </Row>
    </section>
  )
}

export default VisionMission
