import React, { useState } from "react"
import {
  Form,
  FormGroup,
  Input,
  Button,
  InputGroup,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap"
import { Link } from "gatsby"
import { Search } from "react-feather"
import { useStaticQuery, graphql } from "gatsby"

const JobApplication = ({ jobs }) => {
  const isJobsAvailable = jobs.length > 0

  const [query, setQuery] = useState("")
  const [careerRole, setCareerRole] = useState("Career")
  const [filterParam, setFilterParam] = useState(["All"])

  function search(jobs) {
    return jobs.filter((job) => {
      if (careerRole === job.role) {
        if (filterParam === job.category) {
          return (
            job.title.toString().toLowerCase().indexOf(query.toLowerCase()) > -1
          )
        } else if (filterParam == "All") {
          return (
            job.title.toString().toLowerCase().indexOf(query.toLowerCase()) > -1
          )
        }
      }
    })
  }

  return (
    <section>
      <div className="container">
        <h2 className="ms-4 application-title">Job Application</h2>
        <Nav fill pills>
          <NavItem>
            <NavLink
              className={`nav-career ${careerRole == "Career" ? "active" : ""}`}
              onClick={() => setCareerRole("Career")}
            >
              Career
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={`nav-career ${
                careerRole == "Internship" ? "active" : ""
              }`}
              onClick={() => setCareerRole("Internship")}
            >
              Internship
            </NavLink>
          </NavItem>
        </Nav>
        <Form className="mt-3">
          <FormGroup className="d-flex ms-auto app-form">
            <InputGroup>
              <Input
                type="select"
                className="me-3"
                onChange={(e) => {
                  setFilterParam(e.target.value)
                }}
              >
                <option hidden>Categories</option>
                <option value="All">All</option>
                <option value="Analytical">Analytical</option>
                <option value="Clinical">Clinical</option>
                <option value="Clinical Trial">Clinical Trial</option>
                <option value="Finance">Finance</option>
                <option value="Human Resource Team">Human Resource Team</option>
                <option value="IT">IT</option>
                <option value="Quality Assurance">Quality Assurance</option>
                <option value="Sales and Marketing">Sales and Marketing</option>
              </Input>
            </InputGroup>
            <InputGroup>
              <Input
                type="text"
                placeholder="Search.."
                className="p-1"
                onChange={(e) => setQuery(e.target.value)}
              />
              <Button
                style={{ backgroundColor: "#00623D" }}
                onClick={(e) => e.preventDefault()}
              >
                <Search size={16} />
              </Button>
            </InputGroup>
          </FormGroup>
        </Form>
      </div>
      <div
        className="jobs-container pb-3"
        style={{ backgroundColor: "#F8F8F8" }}
      >
        <div className="container job-grid">
          {isJobsAvailable ? (
            search(jobs).map((job) => (
              <div className="job-card">
                <h2 className="job-position mb-4">{job.title}</h2>
                <Link to={`/AboutUs/Careers/${job.slug}`}>
                  <button className="btn-jobs">Apply Now</button>
                </Link>
              </div>
            ))
          ) : (
            <h2
              style={{ height: "20vh" }}
              className="d-flex justify-content-center align-items-center"
            >
              There are no available job applications at the moment
            </h2>
          )}
        </div>
      </div>
    </section>
  )
}

export default JobApplication
